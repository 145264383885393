/* Customize dots */
.slick-dots li button:before {
  color: orange; /* Change the dot color to orange */
  font-size: 20px; /* Adjust the size of the dots */
}
.slick-dots {
  position: static; /* Remove absolute positioning */
}
.slick-dots li.slick-active button:before {
  color: rgb(255, 72, 0); /* Active dot color */
}

/* Custom arrow styles */
.slick-prev, .slick-next {
  background-color: orange; /* Change arrow background color */
  border-radius: 50%;
  padding: 10px;
}

/* Customize slider container */
.slick-slider {
  margin: 0 -10px; /* Adjust margin between slides */
}

/* Customize each slide */
.slick-slide {
  padding: 10px; /* Adjust padding around each slide */
}

.slick-slide div {
  /* border-radius: 10px; Add rounded corners to slides */
}
.slick-list {
  padding: 0 !important;
}
.ProductCompatibility{
  height: 100%;
  width: 100%;
}

